@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.eot');
  src: url('./assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-Regular.woff') format('woff'),
    url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.eot');
  src: url('./assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Montserrat-SemiBold.woff') format('woff'),
    url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'LEMON MILK';
  src: url('./assets/fonts/LEMONMILK-Medium.eot');
  src: url('./assets/fonts/LEMONMILK-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/LEMONMILK-Medium.woff2') format('woff2'),
    url('./assets/fonts/LEMONMILK-Medium.woff') format('woff'),
    url('./assets/fonts/LEMONMILK-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LEMON MILK';
  src: url('./assets/fonts/LEMONMILK-Bold.eot');
  src: url('./assets/fonts/LEMONMILK-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/LEMONMILK-Bold.woff2') format('woff2'),
    url('./assets/fonts/LEMONMILK-Bold.woff') format('woff'),
    url('./assets/fonts/LEMONMILK-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
  padding: env(safe-area-inset);

  font-family: 'LEMON MILK', sans-serif;
  font-weight: normal;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;

  height: 100%;

  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px;
    line-height: 18px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #070311;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color 0.15s linear;
  position: relative;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
#modal-portal,
#modal-portal-tsx {
  z-index: 2;
}

/** @see https://stackoverflow.com/questions/5069752/ios-4-2-webfont-ttf-s-bold-font-weight-rendering-bug/11879044#11879044 */
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}

strong {
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;

  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

sup,
sub {
  font-size: 0.47em;
  padding: 0 2px;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

b,
strong {
  font-weight: 600;
}

i {
  font-style: italic;
}

a {
  color: inherit;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.35);
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.15s ease-in-out;
  background-color: rgba(255, 255, 255, 0.2) !important;
  z-index: 1000;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
